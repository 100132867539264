import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Tag from "../Tag";

import "./index.scss";

const imageStyle = (headerImage, color) => ({
  backgroundColor: `#${color}`,
  backgroundImage: ` url(${headerImage.publicURL})`
});

const CardHeader = ({ url, image, backgroundColor }) => (
  <Link to={url} href={url}>
    <div className="wrapper" style={imageStyle(image, backgroundColor)} />
  </Link>
);

const Card = ({
  title,
  date,
  url,
  headerImage,
  headerBackgroundColor,
  description,
  categories = []
}) => (
  <div className="col-sm-12 pb-4">
    <div className="custom-card">
      {headerImage && (
        <CardHeader
          url={url}
          image={headerImage}
          backgroundColor={headerBackgroundColor}
        />
      )}
      <div className="data">
        <div className="content">
          <div className="stats">
            <span className="date">{date.split("T")[0]}</span>
            {categories.map(tag => (
              <Tag name={tag.name} key={tag.name} />
            ))}
          </div>
          <Link to={url} href={url}>
            <h4 className="title">{title}</h4>
          </Link>
          <p>{description}</p>
          <Link to={url} href={url}>
            More...
          </Link>
        </div>
      </div>
    </div>
  </div>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  url: PropTypes.string.isRequired,
  headerImage: PropTypes.object,
  headerBackgroundColor: PropTypes.string,
  description: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string)
};

Card.defaultProps = {
  headerImage: {},
  categories: [],
  date: "",
  headerBackgroundColor: ""
};

CardHeader.propTypes = {
  url: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  image: PropTypes.string
};

CardHeader.defaultProps = {
  backgroundColor: "",
  image: ""
};

export default Card;
